/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-10-18",
    versionChannel: "nightly",
    buildDate: "2023-10-18T00:25:07.036Z",
    commitHash: "e6f9796094b645566050c7e45b6f642d4887a149",
};
